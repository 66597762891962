import cartService from "../../services/cart";
import RitualButton from "../global/RitualButton";
import metrics from "../../utils/metrics";
import ProductButton from "./ProductButton";

type Props = {
  oos: boolean;
  location: string;
  animated?: boolean;
  isVisible?: boolean;
  price: number;
  futurePrice?: number;
  discountPrice?: number;
  className?: string;
  selectedPlan?: any;
  product?: any;
};

const AddProductButton = ({
  location,
  oos,
  animated,
  price,
  className,
  discountPrice,
  selectedPlan,
  product,
}: Props) => {
  if (oos) {
    return (
      <RitualButton disabled={true} className="fullwidth">
        Out of Stock
      </RitualButton>
    );
  }

  const handleTrackAddProduct = (e: any) => {
    metrics.track("CTA Clicked", {
      location,
      title: "Add to Cart",
    });

    cartService.openCart(e);
  };

  const simpleButtonProps = {
    actionType: "add-to-cart",
    noNavigate: false,
    product: product,
    trackCTAClick: handleTrackAddProduct,
    location: "Single Product PDP Hero",
    planType: selectedPlan?.billingType || "recurring",
    showPricing: false,
    className,
    productButtonProps: {
      showAnimated: animated,
      discountPrice,
      animatedPrice: price !== discountPrice ? price : undefined,
    },
  };

  const animatedButtonProps = {
    ...simpleButtonProps,
    discountPrice: price,
    showPricing: true,
    price: price !== discountPrice ? price : undefined,
  };

  if (animated) {
    return <ProductButton {...animatedButtonProps} />;
  }
  return <ProductButton {...simpleButtonProps} />;
};

export default AddProductButton;
