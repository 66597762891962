import classNames from "classnames";
import styled from "styled-components";
import {
  getContentfulProductForPlan,
  getPlanForProductSku,
} from "../../utils/planToProduct";
import { Font, Opacity, rem, responsive } from "../../utils/style";

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-0_5);
  margin: var(--spacing-1_5) 0;
`;

const ProductSelectorWrapper = styled.div`
  margin: 0 0 32px;
  display: flex;

  ${responsive.sm`
    margin: 0 0 32px;
  `};

  &.full-width {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-0_5);
    margin: unset;
  }
`;

const ProductButton = styled.button`
  ${Font.circular}
  border-radius: 8px;
  background: #fff;
  border: ${(props) =>
    props.active
      ? "2px solid rgb(20, 43, 111)"
      : `2px solid rgba(20, 43, 111, ${Opacity.light})`};
  color: ${(props) =>
    props.active ? "rgb(20, 43, 111)" : `rgba(20, 43, 111, ${Opacity.light})`};

  height: 40px;
  width: 100%;

  transition: 250ms;
  font-size: ${rem(14)};
  font-weight: 500;
  letter-spacing: 0px;

  ${responsive.sm`
    width: auto;
    height: auto;
    padding: 0 24px;
  `}

  ${responsive.md`
    height: 42px;
    font-size: ${rem(16)};
  `}

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:not(:last-of-type) {
    margin-right: 16px;
  }

  &.full-width {
    width: 100%;
    height: 56px;
    padding: var(--spacing-1) 16px 16px;
    gap: var(--spacing-1);
    border-radius: var(--spacing-0_25);
    margin: unset;
  }
`;

const ScentSelector = ({
  products,
  selectedProduct,
  onClick,
  fullWidth = false,
  simpleLabel = false,
}) => {
  if (!products || products.length < 2) return null;

  return (
    <SelectorContainer>
      {simpleLabel && (
        <span className="typography-body2 font-circular">
          {"Select Your Essence"}
        </span>
      )}
      <ProductSelectorWrapper
        className={classNames({ "full-width": fullWidth })}
      >
        {products?.map((product, index) => {
          if (!product || !product.sku) return null;

          const plan = getPlanForProductSku(product.sku);

          if (!plan) return null;

          const contentfulProduct = getContentfulProductForPlan(plan.id);
          const label = simpleLabel
            ? plan.variantDescription.replace(/ Essenced/g, "")
            : plan.variantDescription;

          return (
            <ProductButton
              className={classNames({ "full-width": fullWidth })}
              active={selectedProduct.sku === product.sku}
              key={index}
              onClick={() => onClick(contentfulProduct)}
            >
              {label}
            </ProductButton>
          );
        })}
      </ProductSelectorWrapper>
    </SelectorContainer>
  );
};

export default ScentSelector;
